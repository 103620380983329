.footer{
    padding: 0 20px 0 246px;

    [class*="container-"] {
      padding: 0;
    }

    .nav{
        display: inline-block;
        float: left;
        margin-bottom: 0;
        padding: 0;
        list-style: none;
    }

    .nav-item{
      display: inline-block;

      &:first-child a {
        padding-left: 0;
      }
    }

    .nav-link {
      color: $white;
      padding: $padding-base-vertical;
      font-size: $font-size-sm;
      text-transform: uppercase;
      text-decoration: none;

      &:hover{
          text-decoration: none;
      }
    }

    .copyright.contact{
        font-size: $font-size-sm;
        line-height: 1.8;
        color: $white;
    }

    .contact div{
      display: inline-block;
      padding-right: 20px;
    }

    .contact a {
      margin-right: 10px;
    }

    .contact .email .address{
      margin-left: 5px;
    }
    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}

@include media-breakpoint-down(md){

  .footer {
    .copyright {
        text-align: left;
    }
  }
}

@include media-breakpoint-up(xl){
  .footer {
    .copyright {
      margin-top: 5px;
    }
    .contact {
      margin-top: 5px;
    }
  }
}

@include media-breakpoint-down(sm){
  .footer {
    nav {
      margin-bottom: 5px;
    }

    .copyright.contact{
      text-align: center;
    }
  }
}

@include media-breakpoint-down(sm){
  .footer {
    .copyright.contact {
      text-align: center;
    }
    .nav {
      float: none;
    }
  }
}

@include media-breakpoint-between (md, lg){
  .footer {
    .copyright {
      margin-top: 5px;
    }
    .contact{
      margin-top: 5px;
    }
  }
}
