.account-tabs > div {
  margin-top: 12px;
  margin-bottom: -25px;
}

.nav-open {
  #main-div {
    margin-top: 17px;
  }
}
.first-row > div > div.dropdown {
  margin-top: 0.25rem;
}
@media (min-width: 576px) {
  .text-sm-right {
    text-align: right;
  }
}
#main-div {
  h1 {
    margin-bottom: 20px;
  }
  @media (min-width: 1200px) {
    margin-top: 17px;
  }
}
.policy-modal {
  transform: translate(0, 8%) !important;
  max-width: 70vw;
}

.didnt-run-time-input {
  height: 36px !important;
  width: 102px !important;
}

.didnt-run-time-picker {
  margin-top: 0.5px;
  & .mx-datepicker-popup {
    width: 100px;
    & .mx-calendar {
      width: 100px;
      & .mx-calendar-header {
        display: none;
      }
    }
    & .mx-calendar-content {
      width: 80px;
    }
  }
}

.select-filter {
  & .el-input {
    > input {
      height: calc(1.5125rem + 2px);
      border-radius: 0;
      font-size: 12px;
      border-color: #2b3553;
      background-color: transparent;
      &:focus {
        background: rgba(96, 127, 255, 0.07) !important;
        border-color: #4d67ff !important;
        box-shadow: none;
      }
      &:hover {
        border-color: rgba(29, 37, 59, 0.5);
        cursor: default;
      }
    }
    &:hover .el-select__caret {
      color: #c0c4cc;
    }
    & .el-select__caret {
      width: 14px;
      height: 1.5125rem;
      line-height: 1.5125rem;
      &.el-icon-arrow-up {
        font-size: 10px;
      }
    }
    & .el-input__inner {
      height: calc(1.5125rem + 2px) !important;
    }
  }
  & .el-select__tags {
    max-width: 144px !important;
    overflow: hidden;
    height: 100%;
    & .el-tag {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 2px;
      height: 22px;
      padding: 0 3px 0 3px;
      line-height: 20px;
      color: white;
      & .el-tag__close {
        right: -2px;
      }
    }
  }
}

.dashboard-companies-filter {
  height: 36px !important;
  cursor: pointer;
  & .el-input {
    > input {
      height: 36px !important;
      background-color: $primary !important;
      border-color: white;
      &:focus {
        background-color: $primary !important;
      }
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: white;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: white;
      }
    }
    & .el-input__inner {
      height: 36px !important;
    }
    & .el-select__caret {
      color: white;
      &.el-icon-arrow-up {
        font-size: 12.7px;
        width: 25px;
        height: 100%;
      }
    }
  }
  & .el-tag__close {
    background-color: #c0c4cc !important;
  }
}

.mx-time-picker-item.cell {
  color: #1d253b;
}

.label-switch {
  position: relative;
  left: -10px;
  top: -4px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  &.label-right {
    left: 10px;
  }
}

.reporting-table {
  & .form-check-label {
    padding-left: 5px;
  }
}

.fixed-header {
  & .select-all {
    position: sticky;
    top: 0;
    z-index: 2;
    height: 35px;
    padding-left: 5px;
    background-color: #27293d;
    & .form-check-label {
      padding-left: 30px;
    }
  }
  & .not-all-checked .form-check-sign::after {
    content: '\EA47';
  }
  & > table {
    & > thead {
      & > tr:nth-child(1) > th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #27293d;
      }
      & > tr:nth-child(1) > div {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #27293d;
      }
      & > tr:nth-child(2) > th {
        position: sticky;
        z-index: 2;
        background-color: #27293d;
      }
    }
  }
}

.reporting-table-checkbox {
  margin-top: 0;
  & span {
    &::before,
    &::after {
      width: 25.5px !important;
      height: 25.5px !important;
      font-size: 13px !important;
    }
    &::before {
      top: -3px !important;
    }
    &::after {
      top: 1px !important;
    }
  }
  &.no-thead span {
    &::before {
      top: -3px !important;
    }
    &::after {
      top: 1px !important;
    }
  }
}

.tickets-progress {
  & .progress {
    box-shadow: none !important;
    margin-bottom: 5px;
    background: $dark !important;
    & .progress-bar {
      background: #7ed321;
    }
  }
}

.color-success {
  color: $success;
}

.color-red-error {
  color: $red-error;
}

.datepicker-input {
  height: 32px;
  font-size: 0.75rem;
  border-radius: 0 !important;
}
.weekend-cell {
  background-color: rgba(203, 203, 203, 0.15) !important;
}

.reporting-table-button-badge {
  & .el-badge__content {
    font-size: 9px;
    padding: 0 4px;
    margin-top: 3px;
  }
}

.rule-form-select {
  & .el-tag {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    & .el-tag__close {
      position: absolute;
      right: 3px;
      top: 6px;
    }
  }
}

.close-button {
  background-color: transparent;
  border: 0;
  width: 50px;
  height: 50px;
  padding: 0 !important;
  margin-top: -11px !important;
}
.close-button:hover {
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 50%;
}
.close-button:hover i {
  color: black;
}
.close-button:focus {
  outline: none;
}

.answer input {
  -webkit-text-security: disc !important;
}

.licenses {
  & .license-item {
    & .card-header {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      &:hover {
        background-color: #efefef !important;
      }
    }
    &.selected {
      & .card-header {
        background-color: #efefef !important;
      }
    }
  }
  & .license-item:not(:last-child) {
    border-bottom: 1px solid darkgray;
  }
}

.back-to-login {
  padding: 0 15px;
  margin-top: -20px;
}

.copy-button {
  height: 38px;
  margin-top: 25px;
  border: none;
}

.job-time-picker-input {
  height: 38px !important;
  width: 102px !important;
}

.restore-swal-actions {
  z-index: 0 !important;
  & .swal2-cancel {
    right: 60px;
  }
}

.vtl-node-main {
  cursor: pointer;
}

.vtl-node-main:has(.leaf-selected) {
  background-color: #e5e5e5;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.leaf-selected {
  font-weight: 600;
}

.vtl-operation {
  display: none !important; /* to avoid the label break out */
}

.restore-points-buttons {
  position: absolute;
  top: 271px;
  width: 96%;
  max-height: 182px;
  overflow-y: auto;
  overflow-x: hidden;
}

#site-div:hover #site-tooltip,
#onedrive-div:hover #onedrive-tooltip,
#groupsite-div:hover #groupsite-tooltip {
  display: block;
}

#site-tooltip,
#onedrive-tooltip,
#groupsite-tooltip {
  display: none;
  left: 164px;
  bottom: 75px;
  position: absolute;
  background: #fff;
  border: 1px solid #303133;
  border-radius: 4px;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2;
  min-width: 10px;
  word-wrap: break-word;
}
