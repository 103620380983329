
.notifications .alert {
  position: fixed;
  z-index: 10000;

  &[data-notify='container'] {
    width: 500px;
  }

  &.center {
    margin: 0 auto;
  }
  &.left {
    left: 20px;
  }
  &.right {
    right: 15px;
  }
}
.btn-ack {
  float: right;
  background: none;
  color: white;
  cursor: pointer;
  border: 1px solid white;
  outline: none;
}
.btn-ack:hover {
  color: black;
  opacity: 0.3;
  border: 1px solid black;
  outline: none;
}
.btn-ack:active {
  outline: none;
}
