.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }
}

.sidebar-wrapper ul li div.collapse ul li div.collapse ul li a,
.sidebar-wrapper ul li div.collapse ul li div.collapsing ul li a,
.sidebar-wrapper ul li div.collapsing ul li div.collapse ul li a {
  margin-left: 25px;
}
.sidebar,
.off-canvas-sidebar {
  @include linear-gradient($primary-states, $primary);
  height: calc(100vh);
  width: 230px;
  position: fixed;
  top: -77px;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  display: block;
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.6);
  margin-top: 77px;
  margin-left: 0;//6px;
  border-radius: 0;

  .sidebar-wrapper {
    width: $full-width;
    min-height: 100%;
    max-height: calc(100vh - 705px);
    z-index: 4;
    position: relative;
    overflow: auto;

    .dropdown .dropdown-backdrop {
      display: none !important;
    }

    .navbar-form {
      border: none;
    }

    > .nav,
    .user .info {
      [data-toggle='collapse'] ~ div > ul > li > a {
        span {
          display: inline-block;
          @extend .animation-transition-general;
        }

        .sidebar-normal {
          margin: 0;
          padding-left: 53px;
          position: relative;    
          transform: translateX(0px);
          opacity: 1;
          white-space: nowrap;
          display: block;
          line-height: 23px;
          z-index: 1;
          color: $opacity-8;
        }

        .sidebar-mini-icon {
          text-transform: uppercase;
          width: 34px;
          margin-right: 10px;
          margin-left: 0px;
          font-size: 12px;
          text-align: center;
          line-height: 25px;
          position: relative;
          float: left;
          z-index: 1;
          display: inherit;
          line-height: 24px;
          color: $opacity-8;
        }

        i {
          font-size: 17px;
          line-height: 20px;
          width: 26px;
        }
      }
    }

    [data-toggle='collapse'] ~ div > ul > li:hover > a {
      .sidebar-mini-icon,
      .sidebar-normal {
        color: $white;
      }
    }

    .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-mini-icon {
      opacity: 0;
    }
  }

  .navbar-minimize {
    position: absolute;
    right: 20px;
    top: 2px;
    opacity: 1;
    @extend .animation-transition-general;
  }
  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    display: block;

    .caret {
      top: 18px;
      position: absolute;
      right: 15px;
    }

    li {
      > a + div .nav {
        margin-top: 5px;
        li > a {
          margin-top: 0px;
          padding: 4px 8px 4px 2px;
        }
      }

      > a {
        margin: 2px 15px 0;
        border-radius: $btn-round-radius;
        color: $white;
        display: block;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;        
        padding: 5px 4px;
        line-height: $line-height-lg;
        p {
          font-size: 14px;
        }
        span {
          font-size: 13px;
        }
      }

      &:first-child > a {
        margin: 0 15px;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        p,
        i {
          color: $white;
        }
      }

      &:hover:not(.active) > a i,
      &:focus:not(.active) > a i {
        color: $white;
      }

      &.active > a:not([data-toggle='collapse']) {
        background: transparent;

        i,
        p {
          color: white;
        }

        &:before {
          // content: ' ';
          // position: absolute;
          // height: 6px;
          // width: 6px;
          // top: 15px;
          // left: -4px;
          // background: $white;
          // border-radius: 50%;
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border: 12px solid transparent;
          border-right-color:  $body-bg;
          top: 9px;
          right: -15px;
        }
      }

      &.active > a[data-toggle='collapse'] {
        background: transparent;
        box-shadow: none;
        color: $white;

        i {
          color: $white;
        }

        & + div .nav .active a {
          box-shadow: none;
          .sidebar-mini-icon,
          .sidebar-normal {
            color: $white;
            font-weight: $font-weight-normal;
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border: 12px solid transparent;
            border-right-color:  $body-bg;
            top: 3px;
            right: -15px;
          }
        }
        &:before {
          // content: ' ';
          // position: absolute;
          // height: 6px;
          // width: 6px;
          // top: 22px;
          // left: -4px;
          // background: rgba($white, 0.6);
          // border-radius: 50%;
        }
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
      @extend .animation-transition-general;
    }

    i {
      font-size: 20px;
      float: left;
      margin-right: 12px;
      line-height: 30px;
      width: 34px;
      text-align: center;
      color: $opacity-8;
      position: relative;
    }
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: $full-width;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

    &:after {
      position: absolute;
      z-index: 3;
      width: $full-width;
      height: 100%;
      content: '';
      display: block;
      background: #ffffff;
      opacity: 1;
    }
  }

  .logo {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem $padding-base-horizontal 0.5rem;
    z-index: 4;

    div.logo-mini,
    div.logo-normal {
      @extend .animation-transition-general;
    }

    div.logo-mini {
      opacity: 1;
      float: left;
      // width: 34px;
      min-width: 34px;
      text-align: center;
      margin-left: 12px;
      margin-right: 12px;
      img {
        border-radius: 50%;
        background-color: rgba($white, 0.9);
      }
    }

    div.logo-normal {
      display: block;
      opacity: 1;
      @include transform-translate-x(0px);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background: $opacity-5;
    }

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white;
      line-height: 20px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-base-vertical 0;
      display: block;
      white-space: nowrap;
      font-size: $font-size-base;
      color: $white;
      text-decoration: none;
      font-weight: $font-weight-normal;
      line-height: 30px;
      overflow: hidden;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &[data='blue'] {
    @include linear-gradient($info-states, $info);
    &:before {
      border-bottom-color: $info;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }
  &[data='green'] {
    @include linear-gradient($success-states, $success);
    &:before {
      border-bottom-color: $success;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }
  &[data='orange'] {
    @include linear-gradient($warning-states, $warning);
    &:before {
      border-bottom-color: $warning;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }
  &[data='red'] {
    @include linear-gradient($danger-states, $danger);
    &:before {
      border-bottom-color: $danger;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }

  &[data='vue'] {
    @include linear-gradient($vue-states, $vue);
    &:before {
      border-bottom-color: $vue;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }

  &[data='higher-purple'] {
    @include linear-gradient($higher-purple, $purple);
    &:before {
      border-bottom-color: $higher-purple;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }

  &[data='light-black'] {
    @include linear-gradient($light-black, $light-black);
    &:before {
      border-bottom-color: $light-black;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }

  &[data='light-gray'] {
    @include linear-gradient($light-gray, $light-gray);
    &:before {
      border-bottom-color: $light-gray;
    }
    .simple-text, p, .nav li.active > a:not([data-toggle='collapse']) i,
    .nav li:hover:not(.active) > a i, .nav li.active > a[data-toggle='collapse'] i {
      color:$black !important;
    }   
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {
      .sidebar-mini-icon, .sidebar-normal {
        color:$black !important;
      }
      &:before {
        border-right-color: $body-bg;
      }
    }
    i, .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-mini-icon,
    .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal {
      color: rgba($black, 0.8);
    }
    .logo:after {
      background-color:rgba(34, 42, 66, 0.5) !important;      
    }
    .nav li.active>a[data-toggle=collapse]:before {
      background-color: rgba(34, 42, 66, 0.6) !important;  
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: $body-bg;
    }
  }

  &[data='medium-gray'] {
    @include linear-gradient($medium-gray, $medium-gray);
    &:before {
      border-bottom-color: $medium-gray;
    }
    .simple-text, p, .nav li.active > a:not([data-toggle='collapse']) i,
    .nav li:hover:not(.active) > a i, .nav li.active > a[data-toggle='collapse'] i {
      color:$black !important;
    }   
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {
      .sidebar-mini-icon, .sidebar-normal {
        color:$black !important;
      }
      &:before {
        border-right-color: $body-bg;
      }
    }
    i, .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-mini-icon,
    .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal {
      color: rgba($black, 0.8);
    }
    .logo:after {
      background-color:rgba(34, 42, 66, 0.5) !important;      
    }
    .nav li.active>a[data-toggle=collapse]:before {
      background-color: rgba(34, 42, 66, 0.6) !important;  
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: $body-bg;
    }
  }
  
  &[data='dark-gray'] {
    @include linear-gradient($dark-gray, $dark-gray);
    &:before {
      border-bottom-color: $dark-gray;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }
  
  &[data='white'] {
    @include linear-gradient($white, $white);
    &:before {
      border-bottom-color: $white;
    }
    .simple-text, p, .nav li.active > a:not([data-toggle='collapse']) i,
    .nav li:hover:not(.active) > a i, .nav li.active > a[data-toggle='collapse'] i {
      color:$black !important;
    }   
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {
      .sidebar-mini-icon, .sidebar-normal {
        color:$black !important;
      }
    }
    i, .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-mini-icon,
    .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal {
      color: rgba($black, 0.8);
    }
    .logo:after {
      background-color:rgba(34, 42, 66, 0.5) !important;      
    }
    .nav li.active>a[data-toggle=collapse]:before {
      background-color: rgba(34, 42, 66, 0.6) !important;  
    }
  }

  &[data='burnt-orange'] {
    @include linear-gradient($burnt-orange, $burnt-orange);
    &:before {
      border-bottom-color: $burnt-orange;
    }
    .nav li.active>a[data-toggle=collapse]+div .nav .active a {      
      &:before {
        border-right-color: #dddddd;
      }
    }
    .nav li.active>a:not([data-toggle=collapse]):before {
      border-right-color: #dddddd;
    }
  }

  .user {
    padding-bottom: 20px;
    margin: 20px auto 0;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background: $opacity-5;
    }

    .photo {
      width: 34px;
      height: 34px;
      overflow: hidden;
      float: left;
      z-index: 5;
      margin-right: 10px;
      border-radius: 50%;
      margin-left: 23px;
      box-shadow: $box-shadow-raised;
      @extend .animation-transition-general;

      img {
        width: $full-width;
      }
    }

    a {
      color: $white;
      text-decoration: none;
      padding: $padding-base-vertical 15px;
      white-space: nowrap;
      @extend .animation-transition-general;
    }

    .info {
      > a {
        display: block;
        line-height: 18px;

        > span {
          @extend .animation-transition-general;
          display: block;
          position: relative;
          opacity: 1;
        }
      }

      .caret {
        position: absolute;
        top: 8px;
        right: 18px;
      }
    }
  }
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}
.visible-on-sidebar-mini {
  display: none !important;
}

.off-canvas-sidebar {
  .nav {
    > li > a,
    > li > a:hover {
      color: $white;
    }

    > li > a:focus {
      background: rgba(200, 200, 200, 0.2);
    }
  }
}

.main-panel {
  position: relative;
  float: right;
  width: $full-width;
  height: 100vh;
  max-height: 100vh;
  // border-top: 2px solid $primary;
  background: linear-gradient($background-black, $background-states-black);

  @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

  &[data='blue'] {
    border-top: 2px solid $info;
  }

  &[data='green'] {
    border-top: 2px solid $success;
  }

  &[data='orange'] {
    border-top: 2px solid $warning;
  }

  &[data='red'] {
    border-top: 2px solid $danger;
  }

  &[data='primary'] {
    border-top: 2px solid $primary;
  }
  &[data='vue'] {
    border-top: 2px solid $vue;
  }

  > .content {
    padding: 0 16px 0 246px;
    min-height: calc(100vh - 57px);
  }

  > .navbar {
    margin-bottom: 0;
  }

  .header {
    margin-bottom: 50px;
  }
}

.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }
}

.panel-header {
  height: $sidebar-width;
  padding-top: 80px;
  padding-bottom: 45px;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0c2646),
    color-stop(60%, #204065),
    to(#2a5788)
  );
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  position: relative;
  overflow: hidden;

  .header {
    .title {
      color: $white;
    }
    .category {
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;

      a {
        color: $white;
      }
    }
  }
}

.panel-header-sm {
  height: 135px;
}

.panel-header-lg {
  height: 380px;
}

@include media-breakpoint-down(md) {
  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100vh;
    width: $sidebar-width;
    right: auto;
    left: 0;
    margin: 0;
    border-radius: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    @include transition($sidebar-transition);
    @include transform-translate-x(-$sidebar-width);

    .sidebar-wrapper {
      height: 100vh;
    }
  }

  .minimize-sidebar {
    display: none;
  }

  .nav-open {
    .main-panel {
      right: 0;
      @include transform-translate-x($sidebar-width);
    }

    .sidebar {
      @include transition($sidebar-transition);
      @include transform-translate-x(0px);
      &:before {
        content: unset;
      }
    }

    body {
      position: relative;
      overflow-x: hidden;
    }

    .menu-on-right {
      .main-panel {
        @include transform-translate-x(-$sidebar-width);
      }

      .navbar-collapse,
      .sidebar {
        @include transform-translate-x(0px);
      }

      .bodyClick {
        right: $sidebar-width;
        left: auto;
      }
    }
  }

  .menu-on-right {
    .sidebar {
      left: auto;
      right: 0;
      @include transform-translate-x($sidebar-width);
    }
  }

  .bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: $sidebar-width;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .wrapper {
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .main-panel {
    width: 100%;
    .content {
      padding-left: 30px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .welcome {
    font-size: 2rem !important;
  }
}

@include media-breakpoint-up(xl) {
  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
  }

  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }
    .visible-on-sidebar-mini {
      display: inline-block !important;
    }

    .sidebar {
      width: 80px;

      .sidebar-wrapper {
        width: 100% !important;
        overflow: hidden;
      }
    }

    .sidebar {
      display: block;
      z-index: 1051;
      box-shadow: $sidebar-box-shadow;

      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }

      .sidebar-wrapper {
        > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user
          .info
          [data-toggle='collapse']
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .info > a > span,
        > .nav li > a p, .sidebar-footer .nav li p, #divWithButton .nav li p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }

      .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-mini-icon {
        opacity: 1;
      }
    }

    .sidebar:hover {
      width: $sidebar-width;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0);
        }
      }

      .navbar-minimize {
        opacity: 1;
      }
      .sidebar-wrapper {
        width: $sidebar-width;

        > .nav li > a p,
        > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user
          .info
          [data-toggle='collapse']
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .info > a > span, .sidebar-footer .nav li p, #divWithButton .nav li p {
          @include transform-translate-x(0px);
          opacity: 1;
        }
      }

      .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-mini-icon {
        opacity: 0;
      }
    }

    .main-panel {
      > .content {
        padding-left: 96px;
      }
    }   
    .footer {
      padding-left: 96px;
    }
  }

  .navbar-minimize {
    button {
      margin-left: 10px;
      i {
        color: $white;
        font-size: 20px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@include media-breakpoint-between(md, lg) {
  .wrapper {
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .sidebar {
    // height: calc(100vh - 2px);
    position: fixed;
    top: -77px;
    left: 0;
    background-size: cover;
    background-position: center center;
    display: block;
    box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.6);
    // margin-top: 79px;
    // margin-left: 6px;
    border-radius: 0;
    width: calc(#{$sidebar-width} - 6px) !important;
    right: auto;
    z-index: 1051;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    @include transition($sidebar-transition);
    @include transform-translate-x(-$sidebar-width);

    .sidebar-wrapper {
      height: 100vh;
      .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-mini-icon {
        opacity: 0;
      }
      > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal {
        opacity: 1 !important;
      }
    }
  }

  .main-panel {
    @include transition($sidebar-transition);
    .content {
      padding: 0 15px 30px 15px !important;
      > div.row {
        // margin-top: 29px;
      }
    }
  }

  .nav-open {
    .main-panel {
      right: 0;
      @include transform-translate-x(0);
      .navbar {
        margin-left: 245px;
      }
      .content {
        padding: 7px 16px 30px 269px !important; 
      }
    }

    .sidebar {
      @include transition($sidebar-transition);
      @include transform-translate-x(0px);
      &:before {
        content: unset;
      }
    }

    body {
      position: relative;
      overflow-x: hidden;
    }

    .menu-on-right {
      .main-panel {
        @include transform-translate-x(-$sidebar-width);
      }

      .navbar-collapse,
      .sidebar {
        @include transform-translate-x(0px);
      }

      .bodyClick {
        right: $sidebar-width;
        left: auto;
      }
    }
    footer {
      padding-left: 269px;
    }
  }

  .menu-on-right {
    .sidebar {
      left: auto;
      right: 0;
      @include transform-translate-x($sidebar-width);
    }
  }

  .bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: $sidebar-width;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }


  .sidebar-mini .sidebar .logo a.logo-normal {
    opacity: 1;
    transform: translate3d(0px, 0, 0);
  }

  .sidebar-mini .sidebar .sidebar-wrapper > .nav li > a p {
    opacity: 1;
  }

  .sidebar-mini .sidebar .sidebar-wrapper .nav li a p {
    opacity: 1 !important;
    transform: translate3d(0px, 0, 0);
  }

  .minimize-sidebar {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .footer {
    padding-left: 16px;
  }
  .main-panel {
    @include transition($sidebar-transition);
    .content {
      padding: 0 16px 0 16px !important;
    }
  }
}  
