.o365provisioning-page {
  min-height: 100vh !important;
  & > .content {
    padding-top: 28px !important;
  }
}

@media screen and (max-height: 860px) {
  .full-page.o365provisioning-page.vmo {
    height: 110vh;
  }
}
@media screen and (min-height: 861px) {
  .full-page.o365provisioning-page.vmo {
    height: 100vh;
  }
}

.login-page,
.unlock-page,
.forgotpassword-page,
.forgotpassworddb-page,
.resetpassworddb-page,
.smsverification-page,
.mobilephoneauth-page,
.o365provisioning-page,
.o365emailverification-page {
  &.vmo {
    background-image: unset !important;
    background: linear-gradient(130deg, #6EC1E4 0%, #62CD70 100%) !important;
    & .card-login, .card-login.card-white {
      .btn.mb-3.btn-block.btn-lg, .w-100.py-2.rounded.my-2 {
        background-color: #6ec1e4;
        background-image: unset;
        &:hover, &:focus {
          background-color: #1397ce !important;
          background-image: unset !important;
        }
      }
      .input-group-prepend .input-group-text {
        border-color: $black !important;
        color: $black-states !important; 
      }

      .form-control {
        color: $black !important;
        border-color: $black !important;
        &:focus {
          border-color: $primary !important;
        }
      }

      .input-group-focus {
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text,
        .form-control {
          background-color: rgba(96, 127, 255, 0.07);
          border-color: $primary !important;
        }
      }

      & .card-header {
        padding: 0;
        padding-top: 2em;
        img {
          border-radius: 15px;
          position: relative;
        }
        & .card-title {
          margin-top: 0;
          margin-left: 0;
          font-size: 3em;
        }
      }
    }
  }
  &.hunter-tech {
    background-image: unset !important;
    background: linear-gradient(130deg, #0d545f 0%, #179cb1 100%) !important;
    & .card-login, .card-login.card-white {
      & .card-header {
        padding: 0;
        padding-top: 2em;
        img {
          width: 75%;
          position: relative;
        }
        & .card-title {
          margin-top: 0;
          margin-left: 0;
          font-size: 3em;
        }
      }
      .btn.mb-3.btn-block.btn-lg, .w-100.py-2.rounded.my-2 {
        background-color: #179cb1;
        background-image: unset;
        &:hover, &:focus {
          background-color: #068ba0 !important;
          background-image: unset !important;
        }
      }
      .input-group-prepend .input-group-text {
        border-color: $black !important;
        color: $black-states !important; 
      }
      .form-control {
        color: $black !important;
        border-color: $black !important;
        &:focus {
          border-color: $primary !important;
        }
      }
      .input-group-focus {
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text,
        .form-control {
          background-color: rgba(96, 127, 255, 0.07);
          border-color: $primary !important;
        }
      }
    }
  }
  &.different-dev {
    background-image: unset !important;
    background: linear-gradient(130deg, #528CE6 0%, #27D3DD 100%) !important;
    & .card-login, .card-login.card-white {
      & .card-header {
        padding: 0;
        padding-top: 2em;
        img {
          width: 75%;
          position: relative;
        }
        & .card-title {
          margin-top: 0;
          margin-left: 0;
          font-size: 3em;
        }
      }
    }
  }
  &.prodatix {
    background-image: unset !important;
    background: linear-gradient(130deg, #fe6602 0%, #514f4b 100%) !important;
    & .card-login, .card-login.card-white {
      & .card-header {
        padding: 0;
        padding-top: 2em;
        img {
          width: 75%;
          position: relative;
        }
        & .card-title {
          margin-top: 0;
          margin-left: 0;
          font-size: 3em;
        }
      }
      .btn.mb-3.btn-block.btn-lg, .w-100.py-2.rounded.my-2 {
        background-color: #fe6602;
        background-image: unset;
        &:hover, &:focus {
          background-color: #fe8824 !important;
          background-image: unset !important;
        }
      }
      .footer-link {
        color: #fe6602;
        &:hover, &:focus {
          color: #fe8824 !important;
        }
      }
    }
  }
  &.managecast {
    background-image: url('https://www.managecast.com/wp-content/uploads/2024/09/technology_bg.png') !important;
    & .card-login, .card-login.card-white {
      & .card-header {
        padding: 0;
        padding-top: 2em;
        img {
          border-radius: 15px;
          position: relative;
          width: 60%;
        }
        & .card-title {
          margin-top: 0;
          margin-left: 0;
          font-size: 3em;
        }
      }
    }
  }
  background-image: url('../../../../public/img/background-datacenter.jpg') !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  min-height: 100vh;
  background: radial-gradient($background-black, $background-states-black);
  .content {
    width: 100%;
    max-width: 450px;
    padding: 0;
    margin: 0;
    position: absolute !important;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
  .card-login {
    border-radius: $border-radius-lg;
    padding-bottom: $padding-base-horizontal;

    .card-header {
      padding: 0 0 100px;
      overflow: hidden;
      img {
        position: absolute;
        max-width: 110% !important;
        @media (min-width: 380px) {
          max-width: 85% !important;
        }
        @media (min-width: 433px) {
          max-width: 78% !important;
        }
      }
      .card-title {
        position: relative;
        font-size: 5em;
        font-weight: 900;
        color: $black;
        text-transform: lowercase;
        margin-left: -5px;
        z-index: 1;
      }
      .upright {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        height: 60px;
        &.zimcom {
          height: 30px;
        }
      }
    }
    .btn-wd {
      min-width: 180px;
    }

    .logo-container {
      width: 65px;
      margin: 0 auto;
      margin-bottom: 55px;

      img {
        width: 100%;
      }
    }

    .input-group:last-child {
      margin-bottom: 40px;
    }

    &.card-plain {
      @include input-coloured-bg(
        $opacity-5,
        $white,
        $white,
        $transparent-bg,
        $opacity-1,
        $opacity-2
      );

      .input-group-addon,
      .form-group.no-border .input-group-addon,
      .input-group.no-border .input-group-addon {
        color: $opacity-8;
      }
    }

    &.card-white {
      background: $white;

      .card-header {
        .card-title {
          color: $white;
        }
      }

      @include form-control-placeholder(rgba($black, 0.4), 1);
      .has-danger {
        .form-control,
        .input-group-prepend .input-group-text {
          border-color: $danger-states;
        }
      }

      .input-group-prepend .input-group-text {
        border-color: rgba($black-states, 0.2);
        color: $black-states;
      }

      .form-control {
        color: $black;
        border-color: rgba($black-states, 0.2);
        &:focus {
          border-color: $primary;
        }
      }

      .form-group.no-border,
      .input-group.no-border {
        .form-control,
        .form-control + .input-group-prepend .input-group-text,
        .form-control + .input-group-append .input-group-text,
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text {
          background-color: $opacity-gray-3;
          &:focus,
          &:active,
          &:active {
            background-color: $opacity-gray-5;
          }
        }

        .form-control {
          &:focus {
            & + .input-group-prepend .input-group-text,
            & + .input-group-append .input-group-text {
              background-color: $transparent-bg;
            }
          }
        }
      }

      .input-group[disabled] {
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text {
          background-color: $black;
        }
      }

      .form-control[disabled],
      .form-control[readonly],
      fieldset[disabled] .form-control {
        background: $light-gray;
        border-color: rgba($black-states, 0.3);
      }

      .input-group-focus {
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text,
        .form-control {
          background-color: rgba(96, 127, 255, 0.07);
          border-color: $primary;
        }

        &.no-border {
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text {
            background-color: $opacity-gray-5;
          }
        }
      }
      .input-group-prepend .input-group-text {
        border-right: none;
      }

      .input-group-append .input-group-text {
        border-left: none;
      }

      .has-danger .form-control:focus,
      .has-success.input-group-focus .input-group-append .input-group-text,
      .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: $danger-states;
      }

      .has-success .form-control:focus,
      .has-success.input-group-focus .input-group-append .input-group-text,
      .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: darken($success, 10%);
      }
    }
  }

  .link {
    font-size: 10px;
    text-decoration: none;
  }

  .card-black {
    .link {
      color: $white;
    }
  }
}

.login-page,
.o365provisioning-page {
  & .card-login .card-header .card-title {
    margin-top: 40px;
    margin-left: 10px;
    font-size: 4em;
  }
}

.card-title span {
  color: white;
}

.full-page {
  & > .content,
  & > .footer {
    position: relative;
    z-index: 4;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-left: 48px;
    padding-right: 48px;

    .container {
      color: $white;
    }
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  &.pricing-page {
    .description {
      margin-bottom: 65px;
      color: rgba($white, 0.7);
    }
  }

  &.register-page {
    .info-horizontal {
      padding: 0px 0px 20px;
    }

    .info-horizontal {
      text-align: left !important;

      .icon {
        margin-top: 0;

        > i {
          font-size: 2em;
        }

        &.icon-circle {
          width: 65px;
          height: 65px;
          max-width: 65px;
          margin-top: 8px;

          i {
            display: table;
            margin: 0 auto;
            line-height: 3.5;
            font-size: 1.9em;
          }
        }
      }
      .description {
        overflow: hidden;
        .info-title {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.pricing-page,
  &.lock-page,
  &.register-page {
    min-height: 100vh;
    background: radial-gradient($background-black, $background-states-black);
  }
}

@include media-breakpoint-down(sm) {
  .landing-page .section-story-overview .image-container:nth-child(2) {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@include media-breakpoint-down(xs) {
  .register-page .full-page > .content {
    padding-top: 50px;
  }

  .page-header {
    .container h6.category-absolute {
      width: 90%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .pricing-page,
  .register-page,
  .login-page,
  .unlock-page,
  .forgotpassword-page,
  .forgotpassworddb-page,
  .resetpassworddb-page,
  .lock-page,
  .smsverification-page,
  .mobilephoneauth-page {
    .navbar .navbar-toggle .navbar-toggler {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .register-page .full-page .content {
    .card-register {
      margin-top: 3rem !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .lock-page,
  .login-page,
  .pricing-page,
  .register-page,
  .unlock-page,
  .forgotpassword-page,
  .forgotpassworddb-page,
  .resetpassworddb-page,
  .smsverification-page,
  .mobilephoneauth-page {
    .navbar {
      .navbar-wrapper .navbar-toggle {
        display: none !important;
      }
    }
  }
}
