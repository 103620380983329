.input-wrapper {
  position: relative;
}
.form-group.has-success .form-control {
  background: rgba(249,249,252,0.48);
  border-color: #4FCE3E !important;
}
.success-brdr .form-control {
  background: rgba(249,249,252,0.48) !important;
  border-color: #4FCE3E !important;
}
input.success-brdr{
  background: rgba(249,249,252,0.48) !important;
  border-color: #4FCE3E !important;
}
div.success-brdr{
  background: rgba(249,249,252,0.48);
  border-color: #4FCE3E !important;
}
select.success-brdr{
  background: rgba(249,249,252,0.48);
  border-color: #4FCE3E !important;
}
select.error-brdr{
  background: rgba(249,249,252,0.48);
  border-color: #FF151F !important;
}

span.success-icon{
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 10px;
}
span.success-icon i{
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  color: #7ED321;
}
.error-brdr .form-control {
  background: rgba(249,249,252,0.48);
  border-color: #FF151F !important;
}
div.error-brdr {
  background: rgba(249,249,252,0.48);
  border-color: #FF151F !important;
}
input.error-brdr{
  background: rgba(249,249,252,0.48);
  border-color: #FF151F !important;
}
span.error-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 1.5;
}
span.error-icon i{
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  color: #FF151F;

}
i.error-msg{
  font-weight: 400;
  font-size: 11px;
  color: #FF1721;
  letter-spacing: 0;
  font-style: normal;
}
.diy-tooltip { position: relative; }
.diy-tooltip span {position: absolute;left: 0px;top: -30px;font-size: 12px}
i.required-field {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #D0021B;
  border-radius: 50%;
  vertical-align: middle;
  margin: 7px 10px 0 0;
}
span.details-title{
  font-size: 14px;
  color: #82879E;
  line-height: 35px;
}
span.details-text{
  font-size: 16px;
  color: #24262B;
  letter-spacing: 0;
  line-height: 35px;
}
div.owner-detail{
  display: inline-block;
  width: 100%;
}
div.owner-detail h3{
  float: left;
  width: auto;
}
div.owner-detail .remove-icon {
  float: right;
  width: auto;
}
div.remove-icon a i{
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  color: #82879E;
}
div.remove-icon a i:hover{
  color: red;
}
.add-owner-wrap{
  display: inline-block;
  width: 100%;
  text-align: right;
}
.add-owner-wrap .add-owner{
  padding: 10px 20px;
  text-align: center;
  border: 2px solid green;
  border-radius: .25rem;
  background-color: green;
  margin: 0 0 20px; /*decreased 30px*/
  display: inline-block;
}

.add-owner{
  padding: 12px  54px;
  text-align: center;
  border: 2px  solid  green;
  border-radius: .25rem;
  background-color: green;
}

.add-owner  span{
  font-size: 15px;
  color: #fff;
  line-height: 23px;
  font-weight: 300;
}
.add-owner  span  i{
  font-size: 15px;
  margin-right: 15px;
}

input[type="email"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder ,
input[type="number"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder{ /* IE 10+ */
  color: #BEC1CD;
  font-size: 16px;
  font-weight: lighter;
  line-height: 23px;
}

input[type="email"]:-moz-placeholder,
input[type="text"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="date"]:-moz-placeholder
{ /* Firefox 18- */
  color: #BEC1CD;
  font-weight: lighter;
}

input.form-control:focus,
textarea.form-control:focus {
  background: rgba(96,127,255,0.07) !important;
  border-color: #4D67FF !important;
  box-shadow: none;
}

.input-group-focus .input-group-prepend .input-group-text {
  border-color: rgb(77, 103, 255) !important;
}

select.form-control:not([size]):not([multiple]){
}

input.form-control:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill,
input.form-control:-ms-autofill, textarea:-ms-autofill, select:-ms-autofill,
input.form-control:-moz-autofill, textarea:-moz-autofill, select:-moz-autofill,
input.form-control::-moz-autofill, textarea::-moz-autofill, select::-moz-autofill{
  background-color: rgba(96,127,255,0.07) !important;
  border-color: #4D67FF !important;
}
select.form-control {
  padding: 6px 13px !important;
}
input.form-control,
select.form-control,
.custom-radio .custom-control-input ~ .custom-control-label {
  font-size: 1rem;
}
textarea.form-control {
  border: 1px solid #2b3553;
  border-radius: 0;//7px;
  font-size: 1rem !important;
}
textarea.form-control:focus {
  border-width: 1px !important;
  border-left: unset !important;
  border-top: unset !important;
  border-right: unset !important;
  border: 1px solid #4D67FF!important;
}
