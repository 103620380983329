
.btn-confirm-days {
  position: absolute;
  top: 4px;
  right: 16px;
  padding: 3px 8px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #73879c !important;
  background-color: transparent !important;
  font-size: 12px !important;
  background-image: none !important;
  font-weight: 400 !important;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    background-image: none !important;
  }
}
