@import 'forms';

.card.card-timeline .timeline > li > .timeline-panel {
  box-shadow: none !important;
}

.spinner {
  margin: 50px auto;
  text-align: center;
}
.spinner > div {
  width: 30px;
  height: 30px;
  background-color: #4d67ff;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 2s infinite ease-in-out both;
  animation: sk-bouncedelay 2s infinite ease-in-out both;
  margin-right: 5px;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.spinner .bounce3 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.spinner .bounce4 {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.spinner .bounce4 {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.8;
  }
  60% {
    opacity: 0.9;
  }
}

.welcome {
  font-size: 1.5rem;
}
.integration-links {
  list-style: none;
  padding-inline-start: 10px;
  li {
    margin-bottom: 5px;
  }
  .vac {
    a {
      width: 225px;
      height: 40px;
      background-color: #007db6 !important;
      padding: 10px 5px;
      background-image: none;
      &:hover,
      &:active,
      &:focus {
        background-image: none !important;
        opacity: 0.8;
      }
    }
    // img { width: 85%; }
  }
  .vcd {
    a {
      color: white;
      width: 225px;
      height: 40px;
      background-color: #565656 !important;
      padding: 6px;
      background-image: none;
      &:hover,
      &:active,
      &:focus {
        background-image: none !important;
        opacity: 0.8;
      }
    }
    img {
      width: 30px;
    }
  }
  .zerto {
    a {
      color: white;
      width: 225px;
      height: 40px;
      background-color: #ca000a !important;
      padding: 10px 6px 6px;
      background-image: none;
      &:hover,
      &:active,
      &:focus {
        background-image: none !important;
        opacity: 0.8;
      }
    }
  }
}

.vtl-node-main { cursor: pointer; padding: 0 0 0 1rem !important; }
.vtl-node-content { padding: 5px 0 0 5px; max-width: 100%; overflow: hidden; word-wrap: break-word; }
.vtl-node-main:has(.leaf-selected) {
  background-color: #e5e5e5;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}
.leaf-selected {
  font-weight: 600;
}
.vtl-node-content > div {
  width: 100%;
  overflow: hidden;
}
.vtl-node-content > div > span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.text-highlighted {
  background-color: #e5e5e5;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}