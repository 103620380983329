
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
.sidebar-footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}
#divWithButton .nav li {
  margin: 0px 15px 0px;
  border-radius: 30px;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 8px;
  line-height: 1.625rem;
}
.fix-sidebar-icon {
  margin-left: auto;
  cursor: pointer;
  @media (max-width: 1200px) {
    .fix-icons {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-wrapper {
      display: none;
    }
  }
  .navbar-toggler-bar {
    display: block;
    position: relative;
    width: 20px;
    height: 1px;
    border-radius: 1px;
    background: white;

    &.navbar-kebab {
      height: 4px;
      width: 4px;
      margin-bottom: 3px;
      border-radius: 50%;
    }

    & + .navbar-toggler-bar {
      margin-top: 7px;
    }

    & + .navbar-toggler-bar.navbar-kebab {
      margin-top: 0px;
    }

    &.bar2 {
      width: 15px;
      transition: width 0.2s linear;
    }
  }
}
#logout-div,
#app-container-div {
  .caret {
    display: none;
  }
}
.browse-apps-container {
  position: absolute;
  padding: 15px;
  bottom: 150px;
  left: 35px;
  width: 280px;
  height: 100px;
  border-radius: 5px;
  background: #f5f6fa;
  z-index: 3000;
  border: 2px solid gray;
  display: flex;
  flex-wrap: wrap;
}
.browse-apps-container button {
  max-height: 60px;
  margin: 10px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.browse-apps-container button i {
  font-size: 30px;
}
.browse-apps-container button:hover {
  opacity: 0.4;
}
.browse-apps-container button:focus {
  outline: none;
}
.disabled-sync {
  pointer-events: none;
}
#spinner {
  float: left;
  margin-right: 16px;
  margin-left: 6px;
}
.rotate-45 {
  transform: rotate(-45deg);
}
.sync {
  // height: 40px;
  margin-left: 15px;
  padding-left: 4px;
  cursor: pointer;
  & .caret {
    display: none;
  }
}
.disabled-sync {
  pointer-events: none;
}
