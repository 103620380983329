@import '../../variables';
.el-select-dropdown {
  border-radius: 0;
}
@mixin select($type, $color) {
  .select-#{$type}.el-select {
  }
  .select-#{$type}.el-select .el-input {
    .el-input__suffix {
      display: flex;
      align-items: center;
    }
    .el-input__icon {
      border-radius: 20px;
      height: 40px;
      width: 30px;
    }

    input {
      background-color: transparent;
      border-width: 1px;
      border-color: rgba(29, 37, 59, 0.5);
      border-radius: 0; //0.4285rem;
      color: $color;
      font-size: 1rem;
      height: calc(2.25rem + 2px);
    }
    &:hover {
      input {
        border-color: $color !important;
      }
    }
    input[disabled] {
      background-color: #1d253b;
      border-color: rgba(29, 37, 59, 0.3);
      color: #344675;
    }
    .el-input__icon {
      color: $color;
    }
  }
  .select-#{$type}.el-select.error-border {
    .el-input {
      input {
        background: rgba(249, 249, 252, 0.48);
        border-color: #ff151f !important;
      }
      i::after,
      i::before {
        content: none;
      }
    }
  }
  .select-#{$type}.el-select.success-border {
    .el-input {
      input {
        background: rgba(249, 249, 252, 0.48);
        border-color: #4fce3e !important;
      }
      i::after,
      i::before {
        content: none;
      }
    }
  }
  .select-#{$type} {
    .el-tag,
    .el-tag.el-tag--info {
      line-height: 24px;
      background-color: $color;
      border: none;
      color: white;
      .el-tag__close {
        width: 20px;
        height: 20px;
        background-color: transparent;

        &:hover {
          background-color: white;
          color: $color;
        }

        &:before {
          margin-top: 2px;
        }
      }
    }
    &.el-select-dropdown__item.selected,
    &.el-select-dropdown__item.selected.hover {
      background-color: $color;
      color: white;
    }
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    &.select-#{$type} {
      color: $color;
    }
  }
}

.el-select .el-input {
  &:hover {
    .el-input__icon,
    input {
      &::placeholder {
        // color: white;
      }
      // color: white;
    }
  }
}

.select-pagination {
  & input {
    border: none;
  }
}

@include select('default', $default);
@include select('info', $info);
@include select('primary', $primary);
@include select('success', $success);
@include select('warning', $warning);
@include select('danger', $danger);
