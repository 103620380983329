/* Icon Restore Portal SideBar Items and Title */
.icon-restore {
  margin-left: 12px;
}

.icon-restore-title {
  margin-right: 16px;
  margin-bottom: 30px;
}

.title-icon {
  width: 32px;
  height: 32px;
}
